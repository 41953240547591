
  import Vue from 'vue'
  import ReportService from '@/services/ReportService';
  import ConnectorService from '@/services/ConnectorService';
  import DeviceService from '@/services/DeviceService';
  import PropertyService from '@/services/PropertyService';
  import DateTimePicker from '@/components/misc/DateTimePicker.vue'
  import MonthDatePicker from '@/components/misc/MonthDatePicker.vue'
  import { RRule, rrulestr } from 'rrule'
  import DatePicker from '@/components/misc/DatePicker.vue'

  import { autoComplete, extractErrorMessage, reportTypedColumns } from '@/utils/Util';
  import TenantService from '@/services/TenantService';
  import UserService from '@/services/UserService';
import PriceService from '@/services/PriceService';
import MeteringService from '@/services/MeteringService';
import TagService from '@/services/TagService';

  const frequencyInputsTable = {
    yearly: {
      rrule: RRule.YEARLY,
      until: true,
      interval: true,
      month: true,
      day_of_month: true,
      day_of_the_week: false
    },
    monthly: {
      rrule: RRule.MONTHLY,
      until: true,
      interval: true,
      month: false,
      day_of_month: true,
      day_of_the_week: false
    },
    weekly: {
      rrule: RRule.WEEKLY,
      until: true,
      interval: true,
      month: false,
      day_of_month: false,
      day_of_the_week: true
    },
    daily: {
      rrule: RRule.DAILY,
      until: true,
      interval: true,
      month: false,
      day_of_month: false,
      day_of_the_week: false
    },
    once: {
      rrule: null,
      until: false,
      interval: false,
      month: false,
      day_of_month: false,
      day_of_the_week: false
    }
  };

  const range = (n: number) => Array.from({ length: n }, (v, k) => k + 1);
  const thirty1 = range(31);
  const thirty = range(30);
  const twenty9 = range(29);

  const daysPerMonth = {
    January: thirty1,
    February: twenty9,
    March: thirty1,
    April: thirty,
    May: thirty1,
    June: thirty,
    July: thirty1,
    August: thirty1,
    September: thirty,
    October: thirty1,
    November: thirty,
    December: thirty1
  };

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];

  export default Vue.extend({
    name: 'ReportCreateDialog',

    components: {
      DateTimePicker,
      MonthDatePicker,
      DatePicker
    },
    props: [],
    data: (vm: any): any => ({
      valid: false,
      onShow: false,
      waitTimeCount: 0,
      loadingDevices: false,
      loadingProperties: false,
      loadingConnectors: false,
      loadingTags: false,
      loadingUsers: false,
      loading: false,
      alertText: "",
      alertVisible: false,
      alertType: "error",
      requiredRule: [
          (v: string) => !!v || vm.requiredError,
      ],
      requiredSelect: [
          (v: any) => (!!v && Object.keys(v).length > 0) || vm.requiredError
      ],
      recurrentReport: false,
      recurrentReportTenant: undefined,
      recurrentReportPrice: undefined,

      showCC: false,
      showBCC: false,
      showAllOptions: 0,
      showAllFilter: 0,

      users: [] as any[],
      devices: [] as any[],
      connectors: [] as any[],
      properties: [] as any[],
      tags: [] as any[],

      selectedTenantIds: [] as string[],

      reportType: "device-report",
      selectAllTenants: false,

      recipients: [] as string[],
      ccRecipients: [] as string[],
      bccRecipients: [] as string[],

      options: {
        locale: 'de',
        dateFormat: 'locale',
        separator: ';',
        format: 'csv',
        aggregateMethod: 'LAST',
        includeAppLink: false,
      },
      filters: {
        tenantIds: [] as string[],
        connectorIds: [],
        propertyIds: [],
        billing: {
          type: 'MONTHLY',
          year: null,
          month: null
        },
        metering: {
          overdueGraceDays: null,
        },
        reading: {
          createdFrom: null,
          createdUntil: null,
          from: null,
          until: null,
        },
        device: {
          createdFrom: null,
          createdUntil: null,
          withDeleted: false,
          deletedFrom: null,
          deletedUntil: null,
          deviceIds: [],
          tagIds: [],
        }
      },
      selectedBillingMonthYear: null,

      fileFormats: [
        { name: "CSV", value: "csv" },
        { name: "XLSX", value: "xlsx" }
      ],
      dateFormats: [
        { name: "ISO", value: "iso" },
        { name: "Locale", value: "locale" },
        { name: "en-US", value: "en-us" },
      ],
      locales: [
        { name: vm.$t('locales.english'), value: "en" },
        { name: vm.$t('locales.german'), value: "de" },        
      ],
      selectedColumns: [] as any,
      aggregateMethods: [
          { text: vm.$t('aggregation.last'), value: "LAST" },  
          { text: vm.$t('aggregation.first'), value: "FIRST" },  
          { text: vm.$t('aggregation.min'), value: "MIN" },  
          { text: vm.$t('aggregation.max'), value: "MAX" },  
          { text: vm.$t('aggregation.sum'), value: "SUM" },  
          { text: vm.$t('aggregation.avg'), value: "AVG" },  
      ],
      billingTypes: [
        { text: vm.$t('timeUnit.year'), value: "YEARLY" },  
        { text: vm.$t('timeUnit.month'), value: "MONTHLY" }, 
      ],
      reportsPrice: undefined,
      // rrule
      note: "",
      timespanValue: 1,
      timespanUnit: "MONTHS",
      orientByCalendarBounds: true,
      frequencyInputsTable,
      daysPerMonth,
      weekdays,
      repetitionFromSelection: "",
      repetitionUntilSelection: "",
      rrule: {
        interval: 1,
        start: new Date(Date.now()).toISOString(),
        frequency: null,
        until_num: null,
        month: null,
        daysOfTheMonth: [],
        daysOfTheWeek: [],
        until_date: null
      },
      timespanUnits: [
        { text: vm.$t('timeUnit.months'), value: "MONTHS" },  
        { text: vm.$t('timeUnit.weeks'), value: "WEEKS" }, 
      ],
    }),
    created() {
        this.setDefaultColumns();
        this.getUsers();

        this.selectedTenantIds = [this.currentTenant];
        this.recurrentReportTenant = this.currentTenant;
    },
    methods: {      
      validate() {
        if(this.createForm.validate() && this.recurrentForm.validate()) {
          this.createReport();
        }
      },
      reset() {
        this.reportType = 'device-report';
        this.recipients = [];
        this.ccRecipients = [];
        this.bccRecipients = [];
        this.setDefaultColumns();

        this.filters = {
          tenantIds: [] as string[],
          connectorIds: [],
          propertyIds: [],
          billing: {
            type: 'MONTHLY',
            year: null,
            month: null
          },
          metering: {
            overdueGraceDays: null,
          },
          reading: {
            createdFrom: null,
            createdUntil: null,
            from: null,
            until: null,
          },
          device: {
            createdFrom: null,
            createdUntil: null,
            withDeleted: false,
            deletedFrom: null,
            deletedUntil: null,
            deviceIds: [],
            tagIds: [],
          }
        };
        this.options = {
          locale: 'de',
          dateFormat: 'locale',
          separator: ';',
          format: 'csv',
          aggregateMethod: 'LAST',
          includeAppLink: false,
        };
        this.selectAllTenants = false;
        this.selectedTenantIds = [this.currentTenant]; // is watched and will trigger of getting entities for the tenant
        this.recurrentReportTenant = this.currentTenant;

        this.showAllOptions = 0;
        this.showAllFilter = 0;

        this.recurrentReport = false;
        this.recurrentReportTenant = undefined;
        this.recurrentReportPrice = undefined;

        this.loadingDevices = false;
        this.loadingProperties = false;
        this.loadingConnectors = false;
        this.loadingTags = false;
        this.loading = false;
        this.reportsPrice = undefined;
        // rrule
        this.note =  "";
        this.timespanValue = 1;
        this.timespanUnit = "MONTHS";
        this.orientByCalendarBounds = true;
        
        this.repetitionFromSelection = "";
        this.repetitionUntilSelection = "";
        this.rrule = {
          interval: null,
          start: new Date(Date.now()).toISOString(),
          frequency: null,
          until_num: null,
          month: null,
          daysOfTheMonth: [],
          daysOfTheWeek: [],
          until_date: null
        };
      },
      async createReport() {
        this.loading = true;

        if(this.recurrentReport) {
            // creates report in metering backend with given config

            // create rrule
            let note = this.note;
            
            const rule = this.parsedRRule;
            // console.log(rule.toString())

            let rrule = rule.toString();

            let config = {} as any;
            config.attributes = {};
            config.attributes.columns = this.selectedColumns;

            config.type = this.reportType;
            config.attributes.recipients = this.recipients;
            if(this.ccRecipients.length > 0)
              config.attributes.cc = this.ccRecipients;
            if(this.bccRecipients.length > 0)
              config.attributes.bcc = this.bccRecipients;

            config.attributes.filters = this.filters;
            config.attributes.options = this.options;

            MeteringService.createReport(this.recurrentReportTenant, rrule, this.orientByCalendarBounds, this.timespanValue, this.timespanUnit, config, note).then((report: any) => {
              this.loading = false;
              this.alertType = "success";
              this.alertText = this.$t('report.successCreate');
              this.alertVisible = true;

              this.$emit("success", report);

              setTimeout(() => { 
                this.alertVisible = false;    
              }, 3000);
            }).catch((err: any) => {
                console.log(err, err.response);  
                this.alertType = "error";
                this.alertText = this.$t('report.errorCreate') + ' ' + extractErrorMessage(err);
                this.alertVisible = true;     
                this.loading = false; 
            });
        } else {
          if(this.reportType === 'device-report') {

            ReportService.createDeviceReport(this.recipients, this.selectedColumns, this.filters, this.options, this.ccRecipients, this.bccRecipients).then(() => {
                this.loading = false;
                this.alertType = "success";
                this.alertText = this.$t('report.successCreate');
                this.alertVisible = true;
                this.waitTimeCount = 30;
                setTimeout(() => { 
                  this.alertVisible = false;    
                }, 3000);
            }).catch((err: any) => {
                console.log(err, err.response);  
                this.alertType = "error";
                this.alertText = this.$t('report.errorCreate') + ' ' + extractErrorMessage(err);
                this.alertVisible = true;     
                this.loading = false; 
            });
          } else if(this.reportType === 'reading-report') {

            ReportService.createReadingReport(this.recipients, this.selectedColumns, this.filters, this.options, this.ccRecipients, this.bccRecipients).then(() => {
                this.loading = false;
                this.alertType = "success";
                this.alertText = this.$t('report.successCreate');
                this.alertVisible = true;
                this.waitTimeCount = 30;
                setTimeout(() => { 
                  this.alertVisible = false;    
                }, 3000);
            }).catch((err: any) => {
                console.log(err, err.response);  
                this.alertType = "error";
                this.alertText = this.$t('report.errorCreate') + ' ' + extractErrorMessage(err);
                this.alertVisible = true;     
                this.loading = false; 
            });
          } else if(this.reportType === 'metering-report') {

            ReportService.createMeteringReport(this.recipients, this.selectedColumns, this.filters, this.options, this.ccRecipients, this.bccRecipients).then(() => {
                this.loading = false;
                this.alertType = "success";
                this.alertText = this.$t('report.successCreate');
                this.alertVisible = true;
                this.waitTimeCount = 30;
                setTimeout(() => { 
                  this.alertVisible = false;    
                }, 3000);
            }).catch((err: any) => {
                console.log(err, err.response);  
                this.alertType = "error";
                this.alertText = this.$t('report.errorCreate') + ' ' + extractErrorMessage(err);
                this.alertVisible = true;     
                this.loading = false; 
            });
          } else if(this.reportType === 'billing-report') {
            if(this.filters.billing.type === 'YEARLY') {
              this.filters.billing.month = null;
            }

            ReportService.createBillingReport(this.recipients, this.selectedColumns, this.filters, this.options, this.ccRecipients, this.bccRecipients).then(() => {
                this.loading = false;
                this.alertType = "success";
                this.alertText = this.$t('report.successCreate');
                this.alertVisible = true;
                this.waitTimeCount = 30;
                setTimeout(() => { 
                  this.alertVisible = false;    
                }, 3000);
            }).catch((err: any) => {
                console.log(err, err.response);  
                this.alertType = "error";
                this.alertText = this.$t('report.errorCreate') + ' ' + extractErrorMessage(err);
                this.alertVisible = true;     
                this.loading = false;  
            });
          } else if(this.reportType === 'iotdevice-report') {


            ReportService.createIotDeviceReport(this.recipients, this.selectedColumns, this.filters, this.options, this.ccRecipients, this.bccRecipients).then(() => {
                this.loading = false;
                this.alertType = "success";
                this.alertText = this.$t('report.successCreate');
                this.alertVisible = true;
                this.waitTimeCount = 30;
                setTimeout(() => { 
                  this.alertVisible = false;    
                }, 3000);
            }).catch((err: any) => {
                console.log(err, err.response);  
                this.alertType = "error";
                this.alertText = this.$t('report.errorCreate') + ' ' + extractErrorMessage(err);
                this.alertVisible = true;     
                this.loading = false;  
            });
          } else {
            this.alertType = "error";
            this.alertText = "Invalid report type.";
            this.alertVisible = true;     
            this.loading = false;  
          }
        }
      },
      autoComplete,
      getAssociatedPrice() {
        this.loadingPrice = true;

        let params = {} as any;

        params.type = 'platform';
        params.subtype = 'reports';
        params.tenantId = this.recurrentReportTenant;

        PriceService.getAllCollectionPages(params).then((prices: any) => {
          this.loadingPrice = false;
          if(prices.length > 0) {
              this.recurrentReportPrice = prices[0];
          }
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('price.loadingError') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;    
          this.loadingPrice = false;  
        });
      },
      getTags() {
        this.tags = [];
        this.loadingTags = true;
        
        TagService.getAllCollectionPages(this.selectedTenantIds[0]).then((tags: any[]) => {
            this.loadingTags = false;

            this.tags = tags;
        }).catch((err: any) => {
            console.log(err, err.response); 
            this.loadingTags = false;     
        });
      },
      async getDevices() {
        this.devices = [];
        this.loadingDevices = true;
        
        try {
          let deviceParams = {} as any;
          
          this.devices = await DeviceService.getAllCollectionPages(this.selectedTenantIds[0], deviceParams);
          this.loadingDevices = false;
        } catch(err: any) {
            console.log(err, err.response); 
            this.loadingDevices = false;     
        }
      },
      async getProperties() {
        this.properties = [];
        this.loadingProperties = true;
        
        try {
          const properties = await PropertyService.getAllCollectionPages(this.selectedTenantIds[0]);

          this.properties = properties;
          this.loadingProperties = false;
        } catch(err: any) {
          console.log(err, err.response); 
          this.loadingProperties = false;     
        }
      },
      async getConnectors() {
        this.connectors = [];
        this.loadingConnectors = true;
        
        ConnectorService.getAllCollectionPages(this.selectedTenantIds[0]).then((connectors: any[]) => {
          this.loadingConnectors = false;

          this.connectors = connectors;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.loadingConnectors = false;    
        });
      },
      getUsers() {
        this.users = [];
        this.loadingUsers = true;
        let params = {};

        UserService.getAllCollectionPages(params).then((users: any[]) => {
          this.loadingUsers = false;

          this.users = users;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.loadingUsers = false;    
        });
      },
      toCurrencyString(price: number) {
        return price ? price.toLocaleString(undefined, { style: 'currency', currency: 'EUR' }) : null;
      },
      formatDate(date: Date): any {

        if(this.options.dateFormat === 'iso') {
          return date.toISOString();
        } else if(this.options.dateFormat === 'locale') {
          return date.toLocaleString().replace(',', '');
        } else if(this.options.dateFormat === 'en-us') {
          return date.toString();
        }
        
        return '';
      },
      mandatoryFilter(filter: string) {
        if(this.reportType === 'device-report') {
          return false;
        } else if(this.reportType === 'reading-report') {
          return filter === 'filter.reading.from' || filter === 'filter.reading.until';
        } else if(this.reportType === 'metering-report') {
          return filter === 'filter.metering.overdueGraceDays';
        } else if(this.reportType === 'billing-report') {
          return false;
        }
      },
      setDefaultColumns() {
        if(this.reportType === 'device-report') {
          this.selectedColumns = ["device_property_name", "device_connector_name", "device_uuid", "device_aksId", "device_localAksId", "device_description", "device_unit", "device_reading_count", "device_reading_value", "device_reading_timestamp"];
        } else if(this.reportType === 'reading-report') {
          this.selectedColumns = ["reading_device_property_name", "reading_device_connector_name", "reading_device_aksId", "reading_device_localAksId", "reading_device_description", "reading_device_unit", "reading_value", "reading_timestamp"];
        } else if(this.reportType === 'metering-report') {
          this.selectedColumns = ["property_name", "property_aksId", "property_identifier", "property_postalCode", "property_city", "property_country", "property_deviceCount", "property_emptyDeviceCount", "property_overdueDeviceCount", "property_lastReading_timestamp", "property_lastReading_value"];
        } else if(this.reportType === 'billing-report') {
          this.selectedColumns = ["billing_type", "billing_subtype", "billing_device_property_name", "billing_device_connector_name", "billing_connector_name", "billing_device_aksId", "billing_device_description", "billing_device_unit", "billing_device_reading_value", "billing_device_reading_timestamp", "billing_device_reading_count", "billing_connector_device_count", "billing_start", "billing_end", "billing_days", "billing_price", "billing_perUsePrice"];
        } else if(this.reportType === 'iotdevice-report') {
          this.selectedColumns = ["iotdevice_uuid", "iotdevice_serialnumber", "iotdevice_type", "iotdevice_description", "iotdevice_hardware_version", "iotdevice_tenant_name", "iotdevice_property_name", "iotdevice_connector_name", 
            "iotdevice_port_portnumber", "iotdevice_port_type", "iotdevice_mapping_device_uuid", "iotdevice_mapping_device_aksId", "iotdevice_mapping_device_description", "iotdevice_mapping_device_unit", "iotdevice_mapping_reading_count",
            "iotdevice_mapping_reading_value", "iotdevice_mapping_reading_timestamp"];
        }
      },
    },
    watch: {
      selectAllTenants(value: boolean) {
        if(value) {
          this.selectedTenantIds = this.$root.$store.state.session.tenants.map((value: any) => value.uuid);
        } else {
          this.selectedTenantIds = [];
        }
      },
      selectedTenantIds(value: string[]) {
        this.filters.tenantIds = value;

        if(value.length === 1) {
          this.getProperties();
          this.getConnectors();
          this.getDevices();
          this.getTags();
        } else {
          this.filters.connectorIds = [];
          this.filters.propertyIds = [];
          this.filters.device.deviceIds = [];
          this.filters.device.tagIds = [];
        }
      },
      reportType(value: string) {
        this.setDefaultColumns();
      },
      selectedBillingMonthYear(value: string) {
        if(value) {
          this.filters.billing.year = parseInt(value.split('-')[0]) as any;
          this.filters.billing.month = parseInt(value.split('-')[1]) as any;
        }
      },
      currentTenant() {
        this.reset();
      },
      recurrentReportTenant() {
        if(this.recurrentReportTenant) {
          // this.getAssociatedPrice();
        }
      },
      recurrentReport() {
        if(this.recurrentReport) {
          this.filters.reading.from = null;
          this.filters.reading.until = null;
        }
      },
      waitTimeCount: {
        handler(value) {
            if (value > 0) {
                setTimeout(() => {
                    this.waitTimeCount--;
                }, 1000);
            }
        },
        immediate: true
      }
    },
    computed: {
      firstExecutionExample() {
        return this.firstExecutionDate ? this.firstExecutionDate.toLocaleDateString() : '';
      },
      firstExecutionDate() {
        return this.parsedRRule ? new Date(this.parsedRRule.all((date: Date, i: number) => i < 1)[0]) : undefined;
      },
      parsedRRule() {
        if(!this.rrule.frequency) {
          return undefined;
        }
        if(!this.recurrentForm.validate()) {
          this.recurrentForm.resetValidation();
          return undefined;
        }

        let start = new Date(Date.now());
        start.setUTCDate(start.getUTCDate()+1);
        if(this.repetitionFromSelection === 'from_date') {
          start = new Date(this.rrule.start);
        }

        let _rrule = {
          dtstart: new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())),
        } as any;

        if(this.rrule.frequency && this.rrule.frequency.length > 0) {
          if(this.rrule.frequency.toLowerCase() === 'once') {
            // special case, once is not supported by rrule so we create 
            // a task with yearly occure but only once
            _rrule.freq = RRule.YEARLY;
            _rrule.count = 1;
          } else {
            _rrule.freq = this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].rrule;
          }
        }
        if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].interval && this.rrule.interval) {
          _rrule.interval = Number(this.rrule.interval);
        }
        if(this.repetitionUntilSelection === 'until_count' && this.rrule.until_num) {
          _rrule.count = Number(this.rrule.until_num);
        }
        if(this.repetitionUntilSelection === 'until_date' && this.rrule.until_date) {
          const until = new Date(this.rrule.until_date);
          _rrule.until = new Date(Date.UTC(until.getFullYear(), until.getMonth(), until.getDate()));
        }
        if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].month && this.rrule.month) {
          _rrule.bymonth = this.rrule.month;
        }
        if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].day_of_month && this.rrule.daysOfTheMonth.length > 0) {
          _rrule.bymonthday = this.rrule.daysOfTheMonth;
        }
        if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].day_of_the_week && this.rrule.daysOfTheWeek.length > 0) {
          _rrule.byweekday = this.rrule.daysOfTheWeek.map((value: string) => this.weekdays.findIndex((day: string) => day === value));
        }
        // console.log(_rrule);

        return new RRule(_rrule);
      },
      firstTimespanExample() {
        if(this.firstExecutionDate && (this.reportType === 'device-report' || this.reportType === 'reading-report')) {
          if(this.orientByCalendarBounds) {
              // orient by calendar bounds
              // depending on timespanUnit, get the end-date of the timespan
              // then subtract the timespanValue timespan to get start-date
              if(this.timespanUnit === 'WEEKS') {
                  let end = new Date(this.firstExecutionDate);
                  end.setUTCHours(0, 0, 0, 0);
                  end.setUTCDate(end.getUTCDate() - end.getUTCDay() + (end.getUTCDay() == 0 ? -6:1));
                  let start = new Date(end);
                  start.setUTCDate(start.getUTCDate()-(this.timespanValue*7));

                  return start.toLocaleDateString() + ' - ' + end.toLocaleDateString();
              } else if (this.timespanUnit === 'MONTHS') {
                  let end = new Date(this.firstExecutionDate);
                  end.setUTCDate(1);
                  end.setUTCHours(0, 0, 0, 0);
                  let start = new Date(end);
                  start.setMonth(start.getMonth()-this.timespanValue);

                  return start.toLocaleDateString() + ' - ' + end.toLocaleDateString();
              }
          } else {
              // ignore calendar bounds
              // substract the timespanValue directly from current date
              if(this.timespanUnit === 'WEEKS') {
                  let end = new Date(this.firstExecutionDate);
                  end.setUTCHours(0, 0, 0, 0);
                  let start = new Date(end);
                  start.setUTCDate(start.getUTCDate()-(this.timespanValue*7));

                  return start.toLocaleDateString() + ' - ' + end.toLocaleDateString();
              } else if (this.timespanUnit === 'MONTHS') {
                  let end = new Date(this.firstExecutionDate);
                  end.setUTCHours(0, 0, 0, 0);
                  let start = new Date(end);
                  start.setUTCMonth(start.getUTCMonth()-this.timespanValue);

                  return start.toLocaleDateString() + ' - ' + end.toLocaleDateString();
              }
          }
        }

        return '';
      },
      recurrentReportPriceString() {
        return this.recurrentReportPrice ? this.toCurrencyString(this.recurrentReportPrice.basePrice) : null;
      },
      typedColumns() {
        return reportTypedColumns(this.reportType);
      },
      years(): any[] {
        const currentYear = new Date(Date.now()).getFullYear();
        let yl = [];
        for (let i = 2000; i <= currentYear; i++) {
            yl.push(i);
        }
        return yl;
      },
      filteredReportTypes(): any {
        let types = [
          { name: this.$t('report.deviceReport'), value: "device-report" },
          { name: this.$t('report.readingReport'), value: "reading-report" },
         { name: this.$t('report.meteringReport'), value: "metering-report" },
        ];

        if(this.isAdmin) {
          types.push({ name: this.$t('report.billingReport'), value: "billing-report" });
          types.push({ name: this.$t('report.iotdeviceReport'), value: "iotdevice-report" });
        }
        return types;
      },
      isRoot(): any {
        return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
            && this.$root.$store.state.session.permissions.role === 'superadmin';
      },
      isAdmin(): any {
          return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
              && (this.$root.$store.state.session.permissions.role === 'superadmin' || 
              this.$root.$store.state.session.permissions.role === 'admin');
      },
      formatDateExample(): any {
        return this.formatDate(new Date(Date.now()));
      }, 
      intervalHint() {
        const rrule = this.rrule;
        return `1 means every ${
          rrule.frequency === "Daily" ? "Day" : rrule.frequency.slice(0, -2)
        }, 2 is every other ${
          rrule.frequency === "Daily" ? "Days" : rrule.frequency.slice(0, -2)
        }`;
      },
      frequencies(): any {
        // TODO add i18n translation
        const keys = Object.keys(frequencyInputsTable);
        return keys.map((value: string) => value.charAt(0).toUpperCase()+value.slice(1))
      },
      months(): any { 
        const keys = Object.keys(daysPerMonth);
        return keys.map((value: string) => value.charAt(0).toUpperCase()+value.slice(1))
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },      
      createForm(): any {
        return this.$refs.createForm;
      },       
      recurrentForm(): any {
        return this.$refs.recurrentForm;
      },  
      requiredError(): any {
        return this.$t('required');
      },
    }
  })
